@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
  overflow-x: hidden;
}

/* Custom styles for the Navbar links */
.nav-link {
  color: white;
  text-decoration: none;
  position: relative;
  padding-bottom: 8px;
  /* Add some padding at the bottom to space out the underline */
}

.nav-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Set the height to 2px to make the underline 2px thick */
  background-color: white;
  /* Set the color of the underline to white */
  visibility: hidden;
  /* Hide the underline by default */
  transform: scaleX(0);
  /* Scale the underline to 0 width initially */
  transition: transform 0.3s ease;
  /* Add a smooth transition effect for the underline */
}

.nav-link:hover:before {
  visibility: visible;
  /* Make the underline visible on hover */
  transform: scaleX(1);
  /* Scale the underline to full width on hover */
}

.quote-box {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}

.show {
  height: 400px;
}

.gallery-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
}

.gallery-container {
  position: relative;
  max-width: 90%;
  max-height: 100%;
  overflow-y: auto;
  margin: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}